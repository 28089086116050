/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Menus} from '../../../../../app/helpers/MenuAccessControl'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <SidebarMenuItem
        menuId={Menus.dashboard.id}
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra012.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-graph-up-arrow'
      />
      {!!currentUser?.last_company_show_menu_map &&
        <SidebarMenuItem
          menuId={Menus.map.id}
          to='/map'
          icon='/media/icons/duotune/maps/map1.svg'
          title={intl.formatMessage({id: 'MENU.MAP'})}
          fontIcon='bi-graph-up-arrow'
        />
      }
      {!!currentUser?.last_company_show_menu_sensors &&
        <SidebarMenuItem
          menuId={Menus.sensors.id}
          to='/sensors'
          icon='/media/icons/duotune/electronics/elc007.svg'
          title={intl.formatMessage({id: 'MENU.SENSORS'})}
          fontIcon='bi-graph-up-arrow'
        />
      }
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Cadastros</span>
        </div>
      </div>
      <SidebarMenuItem
        menuId={Menus.companies.id}
        to='/apps/companies-management'
        title='Empresas'
        fontIcon='bi-person'
        icon='/media/icons/duotune/general/company.svg'
      />
      <SidebarMenuItem
        menuId={Menus['measuring_boxes'].id}
        to='/apps/measuring-box-management'
        title='Caixas'
        fontIcon='bi-person'
        icon='/media/icons/duotune/general/meter.svg'
      />
      <SidebarMenuItem
        menuId={Menus.projects.id}
        to='/apps/projects-management/projects'
        title='Projetos'
        icon='/media/icons/duotune/general/gen062.svg'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        menuId={14}
        to='/apps/operators-management'
        title='Operadores'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/communication/operator.svg'
      />
      <SidebarMenuItem
        menuId={Menus.coordinators.id}
        to='/apps/coordinators-management'
        title='Coordenadores'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/communication/coordinator.svg'
      />
      <SidebarMenuItem
        menuId={Menus.managers.id}
        to='/apps/managers-management'
        title='Gerentes'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/communication/manager.svg'
      />
      <SidebarMenuItem
        menuId={Menus.administrators.id}
        to='/apps/administrators-management'
        title='Administradores'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/communication/admin.svg'
      />
      <SidebarMenuItem
        menuId={Menus.logs.id}
        to='/apps/logs-management/logs'
        title='Históricos'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/abstract/abs027.svg'
      />
    </>
  )
}

export {SidebarMenuMain}
