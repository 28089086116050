import axios, {AxiosResponse} from 'axios'
import {
  MeasuringBoxesWarningsQueryResponse,
  TypeResponseSuccess,
} from './_models'
import { ID, Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

const getOpeningRequestsFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/opening-requests/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const openDoorRelease = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios({
    method: 'post',
    url: `${API_URL}/log-box/nastek/open-door-released/${measuring_box_id}`,
    headers: {
      secret_key: 'X5VRrF&6(@!45dFN{xZ'
    }
  })
  .then((response: Response<TypeResponseSuccess>) => response.data)
}

const openDoorDenied = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/open-door-denied/${measuring_box_id}`, {}, {headers: {secret_key: 'X5VRrF&6(@!45dFN{xZ'}})
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const getAlarmOpenDoorFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-open-door/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmBoltFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-bolt/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmLockDoorFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-lock-door/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmMaximumTimeOpenDoorFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-maximum-time-open-door/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmViolationFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-violation/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmVibrationFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-vibration/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmSoundFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-sound/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmEnergyFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-energy/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmCutCableFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-cut-cable/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const clearAlarm = (alarmId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-alarm/${alarmId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmRequestOpenDoor = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/opening-door-requests/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmDoor = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/doors/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmBolt = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/bolts/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmDoorLock = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/doors-lock/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmVibration = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/vibrations/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmEnergy = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/energy/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmViolation = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/violations/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmSound = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/sound/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmMaxTimeOpenDoor = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/maximum-time-open-door/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const clearAllAlarmCableCut = (companyId: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/clear-all-alarm/cut-cable/company/${companyId}`)
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

export {
  getOpeningRequestsFilter,
  openDoorRelease,
  openDoorDenied,
  getAlarmOpenDoorFilter,
  getAlarmBoltFilter,
  getAlarmLockDoorFilter,
  getAlarmMaximumTimeOpenDoorFilter,
  getAlarmViolationFilter,
  getAlarmVibrationFilter,
  getAlarmSoundFilter,
  getAlarmEnergyFilter,
  getAlarmCutCableFilter,
  clearAlarm,
  clearAllAlarmRequestOpenDoor,
  clearAllAlarmDoor,
  clearAllAlarmBolt,
  clearAllAlarmDoorLock,
  clearAllAlarmVibration,
  clearAllAlarmEnergy,
  clearAllAlarmViolation,
  clearAllAlarmSound,
  clearAllAlarmMaxTimeOpenDoor,
  clearAllAlarmCableCut,
}
