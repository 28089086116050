import { FC, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api'
import { useQuery } from 'react-query'
import { getMeasuringBoxesMaps } from './core/_requests'
import { ID, QUERIES } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { toast } from 'react-toastify'
import { MeasuringBoxModels, MeasuringBoxModelsList, MeasuringBoxType } from '../../modules/apps/measuring-box-management/measuring-box-list/core/_models'
import { EditModal } from '../../modules/apps/measuring-box-management/measuring-box-list/measuring-boxes-edit-modal/EditModal'
import { useListView } from '../../modules/apps/measuring-box-management/measuring-box-list/core/ListViewProvider'

const containerStyle = {
  width: '100%',
  height: '100vh',
}

const GoogleMaps: FC<{googleMapsApiKey: string}> = ({googleMapsApiKey}) => {
  const {itemIdForUpdate, setItemIdForUpdate, setReadOnly} = useListView()
  const {currentUser} = useAuth()
  const company_id = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0

  const {data: measuringBoxesMaps, isFetching} = useQuery(
    `${QUERIES.MEASURING_BOXES_LIST}-MAP`,
    async () => {
      const res = await getMeasuringBoxesMaps({company_id, take: 0, skip: 10000})
      return res.data?.filter((box) => box.lat !== null)
    },
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.error(err)
        toast.error(`Erro ao carregar as caixas no mapa: ${err.message}`)
      },
    }
  )

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey
  })

  // const [map, setMap] = useState(null)

  const greenBoxIcon = '/media/icons/boxes/box-green-32.png'
  const greyBoxIcon = '/media/icons/boxes/box-grey-32.png'

  // const onMapLoad = (map: google.maps.Map) => {
  //   console.log('map: ', map)
  // }

  // const onUnmount = useCallback(function callback(map: any) {
  //   setMap(null)
  // }, [])

  type TypeMarker = {
    id: ID,
    cmb_number: number,
    project_name: string,
    model_type: number,
    bluetooth: string,
    lat: number,
    lng: number,
  }

  const [activeMarker, setActiveMarker] = useState<TypeMarker>()
  const [center, setCenter] = useState({lat: 37.334606, lng: -122.009102})

  const handleMarkerClick = (marker: TypeMarker) => {
    setActiveMarker({...marker, lat: marker.lat + 0.00069})
  };

  useEffect(() => {
    if (measuringBoxesMaps?.length && measuringBoxesMaps.length > 0) {
      setCenter({lat: parseFloat(measuringBoxesMaps[0].lat.toString()), lng: parseFloat(measuringBoxesMaps[0].long.toString())})
    }

  }, [measuringBoxesMaps])

  return !isFetching && isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        // onLoad={onMapLoad}
        // onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
          {measuringBoxesMaps?.length && measuringBoxesMaps.map((box, index) => {
            return (
              <Marker
                position={{
                  ...measuringBoxesMaps[index],
                  lat: parseFloat(measuringBoxesMaps[index].lat.toString()),
                  lng: parseFloat(measuringBoxesMaps[index].long.toString()),
                }}
                icon={measuringBoxesMaps[index].type === MeasuringBoxType.online ?  greenBoxIcon : greyBoxIcon}
                label={{
                  text: measuringBoxesMaps[index]?.box_id ? `${measuringBoxesMaps[index]?.cmb_number}` : 's/num',
                  color: 'black',
                  className: 'mt-14',
                  fontSize: '12px'
                }}
                onClick={() => handleMarkerClick({
                  id: measuringBoxesMaps[index].box_id,
                  cmb_number: measuringBoxesMaps[index].cmb_number,
                  bluetooth: measuringBoxesMaps[index].bluetooth,
                  model_type: measuringBoxesMaps[index].model_type,
                  project_name: measuringBoxesMaps[index].project_name,
                  lat: parseFloat(measuringBoxesMaps[index].lat.toString()),
                  lng: parseFloat(measuringBoxesMaps[index].long.toString()),
                })}
              />
            )
          })
          }
        </>
        {activeMarker && (
          <InfoWindow
            position={{...activeMarker, lng: activeMarker.lng}}
            onCloseClick={() => setActiveMarker(undefined)}
          >
            <div className="me-4 mb-4 ms-1 p-0 mt-0">
              <div className="d-flex fs-3 fw-bold mb-5 align-items-center justify-content-center">
                CAIXA CMB Nº
                <div
                  className="border border-gray-800 border-2 ms-1 rounded text-center"
                  style={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '60px' }}
                  >
                  {activeMarker.cmb_number}
                </div>
              </div>
              <div className="fs-5 mb-5">
                <b>Projeto:</b> {activeMarker.project_name}
              </div>
              <div className="fs-5 mb-5">
                <b>Bluetooth:</b> {activeMarker.bluetooth}
              </div>
              <div className="fs-5 mb-5">
                <b>Modelo:</b> {
                  activeMarker.model_type === MeasuringBoxModels.bluetooth_com_barreira
                    ? MeasuringBoxModelsList.find((model) => model.id === MeasuringBoxModels.bluetooth_com_barreira)?.name || ''
                    : activeMarker.model_type === MeasuringBoxModels.bluetooth_sem_barreira
                    ? MeasuringBoxModelsList.find((model) => model.id === MeasuringBoxModels.bluetooth_sem_barreira)?.name || ''
                    : 'não identificado'
                }
              </div>
              <div className="d-flex gap-4">
                <div>
                  Lat. {activeMarker.lat.toFixed(10)}
                </div>
                <div>
                  Long. {activeMarker.lng.toFixed(10)}
                </div>
              </div>
              <div className="d-flex justify-content-end mt-8">
                <button type="button" className='btn btn-primary p-2' onClick={() => {
                  setReadOnly && setReadOnly(true)
                  setItemIdForUpdate(activeMarker.id)
                }}>
                  Consultar Detalhes
                </button>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <>
        {itemIdForUpdate !== undefined && <EditModal />}
      </>
    </div>
  ) : (
    <div style={{ height: 'calc(( 100vh - 180px)' }}>
      <div className='d-flex align-items-center'>
        <div>
          <span className="spinner-border me-3"></span>
        </div>
        <div className='fs-5 fw-bold pb-1'>
          Aguarde, carregando o mapa...
        </div>
      </div>
    </div>
  )
}

export {GoogleMaps}