import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Company,
  OperatorsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getCompaniesFilter = (query: Record<string, any>): Promise<OperatorsQueryResponse> => {
  return axios
    .get(`${API_URL}/companies/filter`, {params: query})
    .then((d: AxiosResponse<OperatorsQueryResponse>) => d.data)
}

const getCompaniesById = (companyId: ID): Promise<Company | undefined> => {
  return axios
    .get(`${API_URL}/companies/filter/${companyId}`)
    .then((d: AxiosResponse<Company>) => d.data)
}

const createCompany = (operator: Company): Promise<Company | undefined> => {
  return axios
    .post(`${API_URL}/companies`, operator)
    .then((response: AxiosResponse<Response<Company>>) => response.data)
    .then((response: Response<Company>) => response.data)
}

const updateCompany = (operator: Company): Promise<Company | undefined> => {
  return axios
    .put(`${API_URL}/companies/${operator.id}`, operator)
    .then((response: AxiosResponse<Response<Company>>) => response.data)
    .then((response: Response<Company>) => response.data)
}

const getAllExportXls = (): Promise<Company[]> => {
  return axios
    .get(`${API_URL}/companies`)
    .then((d: AxiosResponse<Company[]>) => d.data)
}

export {
  getCompaniesFilter,
  getCompaniesById,
  createCompany,
  updateCompany,
  getAllExportXls,
}
