import { FC, useEffect, useState } from 'react'
import { getUserCompanies } from './header-menus/core/_requests'
import { updateOperator } from '../../../../app/modules/apps/operators-management/operators-list/core/_requests'
import { UserCompany, UserCompaniesQueryResponse } from './header-menus/core/_models'
import { getAuth, setAuth } from '../../../../app/modules/auth/core/AuthHelpers'
import { AuthModel } from '../../../../app/modules/auth/core/_models'
import { useAuth } from '../../../../app/modules/auth'
import { Operator } from '../../../../app/modules/apps/operators-management/operators-list/core/_models'
import { trigger } from '../../../../app/helpers/events'
import { COMPANIES_REFRESH } from '../../../../app/modules/apps/companies-management/companies-list/CompaniesList'
import { OPERATORS_REFRESH } from '../../../../app/modules/apps/operators-management/operators-list/OperatorsList'
import { COORDINATORS_REFRESH } from '../../../../app/modules/apps/coordinators-management/coordinator-list/CoordinatorsList'
import { MANAGERS_REFRESH } from '../../../../app/modules/apps/managers-management/managers-list/ManagersList'
import { ADMINISTRATORS_REFRESH } from '../../../../app/modules/apps/administrators-management/administrators-list/AdministratorsList'
import { MEASURING_BOXES_REFRESH } from '../../../../app/modules/apps/measuring-box-management/measuring-box-list/List'
import { PROJECTS_REFRESH } from '../../../../app/modules/apps/projects-management/projects-list/List'
import { LOGS_BOX_REFRESH } from '../../../../app/modules/apps/logs-management/logs-box-list/List'

export const CurrentUserCompany: FC = () => {
  const [companies, setCompanies] = useState<UserCompany[]>()
  const [showCompaniesList, setShowCompaniesList] = useState(false)
  const [newUserCompany, setNewUserCompany] = useState<UserCompany>()
  const [companiesLoading, setCompaniesLoading] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()

  const toggleCompaniesList = async () => {
    const show = !showCompaniesList
    setShowCompaniesList(show)
    if (show) {
      try {
        setCompaniesLoading(true)
        const res = await getUserCompanies(currentUser!.id) as UserCompaniesQueryResponse
        setCompanies(res.data)
        setCompaniesLoading(false)
      } catch {
        setCompanies([])
        setCompaniesLoading(false)
      }
    }
  }

  const handleRadioChange = (newCompany: UserCompany) => {
    setNewUserCompany({
      id: newCompany.id,
      name: newCompany.name,
      show_menu_map: newCompany.show_menu_map,
      show_menu_sensors: newCompany.show_menu_sensors
    })
  };

  useEffect(() => {
    setNewUserCompany({ id: currentUser?.last_company_id, name: currentUser?.last_company_name, show_menu_map: currentUser?.last_company_show_menu_map, show_menu_sensors: currentUser?.last_company_show_menu_sensors })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCompaniesList])

  const changeUserCompany = () => {
    try {
      const currentAuth = getAuth();
      if (newUserCompany?.id && newUserCompany?.name && currentAuth) {
        const newAuth: AuthModel = {
          ...currentAuth,
          user: {
            ...currentAuth.user,
            last_company_id: newUserCompany.id,
            last_company_name: newUserCompany.name,
            last_company_show_menu_map: newUserCompany.show_menu_map,
            last_company_show_menu_sensors: newUserCompany.show_menu_sensors,
          }
        }
        setAuth(newAuth)
        setCurrentUser({
          ...currentAuth.user,
          last_company_id: newUserCompany.id,
          last_company_name: newUserCompany.name,
          last_company_show_menu_map: newUserCompany.show_menu_map,
          last_company_show_menu_sensors: newUserCompany.show_menu_sensors,
        })
        updateOperator({ id: currentUser?.id, last_company: newUserCompany.id } as Operator).then(() => {
          trigger(COMPANIES_REFRESH)
          trigger(OPERATORS_REFRESH)
          trigger(COORDINATORS_REFRESH)
          trigger(MANAGERS_REFRESH)
          trigger(ADMINISTRATORS_REFRESH)
          trigger(MEASURING_BOXES_REFRESH)
          trigger(PROJECTS_REFRESH)
          trigger(LOGS_BOX_REFRESH)
        })
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      toggleCompaniesList()
    }
  }

  return (
    <div>
      <div>
        <button
          className="btn btn-flex btn-secondary px-3"
          style={{ height: "50px" }}
          onClick={toggleCompaniesList}
        >
          <span className="svg-icon svg-icon-2x"><img src='/media/icons/duotune/general/company.svg' alt='' height='30px' /></span>
          <span className="d-flex flex-column align-items-start ms-2">
            <span className="fs-3 fw-bolder">{currentUser?.last_company_name || ' Empresa não definida'}</span>
            <span className="fs-8">Clique aqui p/ mudar de empresa</span>
          </span>
        </button>
      </div>
      {showCompaniesList && (
        <div className="card card-custom position-absolute bg-secondary" style={{ marginTop: "4px", marginRight: "25px", zIndex: 1 }}>
          <form noValidate id='kt_modal_create_app_form' onSubmit={companies?.length ? changeUserCompany : toggleCompaniesList}>
            <div className="card-header">
              <h3 className="card-title">Empresas</h3>
              <div className="card-toolbar">
                <button type="submit" className="btn btn-sm btn-light" data-kt-indicator={companiesLoading ? "on" : "off"}>
                  <span className="indicator-label">
                    {companies?.length ? 'Selecionar' : 'Fechar'}
                  </span>
                  <span className="indicator-progress">
                    Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="card-body card-scroll h-200px">
              <div className="mb-10">
                {companies?.length ? (
                  <span className='d-flex flex-column gap-2'>
                    {
                      companies.map((company) => {
                        return (
                          <span className='form-check form-check-custom form-check-solid' key={`${company.id}`}>
                            <input
                              className='form-check-input me-3'
                              name='company'
                              type='radio'
                              value={`${company.id}`}
                              id='kt_modal_update_role_option_0'
                              onChange={() => handleRadioChange(company)}
                              checked={company.id === newUserCompany?.id}
                            />
                            <label className='fs-6 ms-2'>{company.name}</label>
                          </span>
                        )
                      })
                    }
                  </span>
                ) : (
                  !companiesLoading &&
                    <span className='d-flex flex-column gap-2'>Não há empresas vinculadas para esse usuário.</span>
                )}
              </div>
            </div>
            <div className="card-footer">
              Selecione a empresa que deseja trabalhar.
            </div>
          </form>
        </div>
      )}
    </div>
  )
}
