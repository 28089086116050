import { FC, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify';
import { LedGreen, LedRed } from '../../../components/Leds'
import { Bolts, DoorLocks, MeasuringBoxesWarnings, Ports, SensorTypes } from '../core/_models'
import { clearAlarm } from '../core/_requests'
import { AlertModal } from '../../../components/AlertModal'

type Props = {
  sensorTypeId: number,
  logBox: MeasuringBoxesWarnings;
  refetch: () => void;
}

const MeasuringBoxDefault: FC<Props> = ({sensorTypeId, logBox, refetch}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [showModalClearAlarm, setShowModalClearAlarm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleClearAlarm(logBox: MeasuringBoxesWarnings) {
    try {
      setIsLoading(true)
      const ret = await clearAlarm(logBox.id)
      if (ret?.success) {
        toast.success(`Alarme da Caixa CMB nº ${logBox.cmb_number} foi limpo com sucesso!`)
        refetch()
      } else {
        toast.error(`Ocorreu um erro ao tentar limpar o alarme da Caixa CMB nº ${logBox.cmb_number}`)
      }
    } catch (error: any) {
      toast.error(`Ocorreu um erro ao tentar limpar o alarme da Caixa CMB nº ${logBox.cmb_number}: ${error.message}`)
    } finally {
      setIsLoading(false)
      setShowModalClearAlarm(false)
    }
  }

  return (
    <div className="border-dark border-opacity-50 bg-opacity-10 border p-1 pt-0 rounded" 
      style={{ width: "160px" }}
      >
      <div className='d-flex justify-content-between'>
        <div id='project_name' title={`Projeto: ${logBox.project_name}`} className='text-truncate' >
          <span className="fs-7 fw-bold"></span>
          <span className="fs-6 fw-bold">{logBox.project_name}</span>
        </div>
        <div
          title="Limpar Alarme"
          onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
          onClick={() => setShowModalClearAlarm(true)}
          >
          <i className={isHovered ? 'bi-trash3-fill' : 'bi-trash3'} style={{cursor: "pointer"}}></i>
        </div>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold">Nº CMB: </span>
        <span className="fs-6 fw-bold">{logBox.cmb_number}</span>
      </div>
      <div id='bluetooth' title='Número Bluetooth'>
        <span className="fs-7 fw-bold"><i className="bi-bluetooth"></i> </span>
        <span className="fs-6 fw-bold">{logBox.bluetooth}</span>
      </div>
      <div id='request_from_user' title={`Usuário Solicitante: ${logBox.user_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-person-circle"></i> </span>
        <span className="fs-6 fw-bold">{logBox.user_name}</span>
      </div>
      <div id='date_alarm' title={`Data e Hora do Alarme`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-alarm"></i> </span>
        <span className="fs-6 fw-bold">{moment(logBox.created_at).format('DD/MM/YY HH:mm:ss')}</span>
      </div>

      <div className="border-bottom border-dark border-opacity-50"></div>

      <div id="leds" className="d-flex justify-content-between me-1">
        <span className="fs-7 fw-bold">
          {sensorTypeId === SensorTypes.door.id
            ? <div><i className={SensorTypes.door.icon}></i> {Ports[logBox.type_id as number]?.description}</div>
            : sensorTypeId === SensorTypes.bolt.id
            ? <div><i className={SensorTypes.bolt.icon}></i> {Bolts[logBox.type_id as number]?.description}</div>
            : sensorTypeId === SensorTypes.door_lock.id
            && <div><i className={SensorTypes.door_lock.icon}></i> {DoorLocks[logBox.type_id as number]?.description}</div>
          }
        </span>
        {sensorTypeId !== SensorTypes.door.id && sensorTypeId !== SensorTypes.bolt.id && sensorTypeId !== SensorTypes.door_lock.id
          ? <div className="animation-blink" style={{height: "17px"}}><LedRed /></div>
          : (sensorTypeId === SensorTypes.door.id || sensorTypeId === SensorTypes.bolt.id || sensorTypeId === SensorTypes.door_lock.id) && logBox.enabled
          ? <div className="animation-blink" style={{height: "17px"}}><LedRed /></div>
          : (sensorTypeId === SensorTypes.door.id || sensorTypeId === SensorTypes.bolt.id || sensorTypeId === SensorTypes.door_lock.id) && !logBox.enabled
          && <div className="animation-blink" style={{height: "17px"}}><LedGreen /></div>
        }
      </div>

      <AlertModal
        id='alert-show-modal-clear-alarm'
        show={showModalClearAlarm}
        title='Limpar Alarme'
        key={'alert-show-modal-clear-alarm'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => handleClearAlarm(logBox)}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalClearAlarm(false)}}
        isLoading={isLoading}
        >
        {`Confirma a limpeza do alarme da caixa CMB nº ${logBox.cmb_number}?`}
      </AlertModal>

    </div>
  );
}

export {MeasuringBoxDefault}