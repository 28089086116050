import { ListViewProvider } from "../../modules/apps/measuring-box-management/measuring-box-list/core/ListViewProvider"
import { MapPage } from "./MapPage"

export function Map() {
  return (
    <ListViewProvider>
      <MapPage />
    </ListViewProvider>
  )
}
