import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment';
import { LedYellow } from '../../../components/Leds'
import { MeasuringBoxesWarnings } from '../core/_models';
import { openDoorRelease, openDoorDenied, clearAlarm } from '../core/_requests'
import { AlertModal } from '../../../components/AlertModal'
import { ID } from '../../../../_metronic/helpers'

type Props = {
  openingRequest: MeasuringBoxesWarnings;
  refetch: () => void;
}

const MeasuringBoxOpeningRequests: FC<Props> = ({openingRequest, refetch}) => {
  const [showModalDoorRelease, setShowModalDoorRelease] = useState(false)
  const [showModalDoorDenied, setShowModalDoorDenied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [showModalClearAlarm, setShowModalClearAlarm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenDoorRelease = async (box_id: ID, cmb_number: ID) => {
    setIsLoading(true)
    const res = await openDoorRelease(box_id)
    setIsLoading(false)
    setShowModalDoorRelease(false)
    if (res?.success) {
      refetch()
      toast.success(`Envio da aprovação de abertura da caixa ${cmb_number} realizado com sucesso!`)
    } else {
      toast.error(`Erro no envio da aprovação de abertura da caixa ${cmb_number}`)
    }
  }

  const handleOpenDoorDenied = async (box_id: ID, cmb_number: ID) => {
    setIsLoading(true)
    const res = await openDoorDenied(box_id)
    setIsLoading(false)
    setShowModalDoorRelease(false)
    if (res?.success) {
      refetch()
      toast.success(`Envio da negação de abertura da caixa ${cmb_number} realizado com sucesso!`)
    } else {
      toast.error(`Erro no envio da negação de abertura da caixa ${cmb_number}`)
    }
  }

  async function handleClearAlarm(logBox: MeasuringBoxesWarnings) {
    try {
      setIsLoading(true)
      const ret = await clearAlarm(logBox.id)
      if (ret?.success) {
        toast.success(`Alarme da Caixa CMB nº ${logBox.cmb_number} foi limpo com sucesso!`)
      } else {
        toast.error(`Ocorreu um erro ao tentar limpar o alarme da Caixa CMB nº ${logBox.cmb_number}`)
      }
    } catch (error: any) {
      toast.error(`Ocorreu um erro ao tentar limpar o alarme da Caixa CMB nº ${logBox.cmb_number}: ${error.message}`)
    } finally {
      setIsLoading(false)
      setShowModalClearAlarm(false)
    }
  }

  return (
    <div className="border-dark border-opacity-50 bg-opacity-10 border p-1 pt-0 rounded" 
      style={{ width: "160px" }}
      >
      <div className='d-flex justify-content-between'>
        <div id='project_name' title={`Projeto: ${openingRequest.project_name}`} className='text-truncate' >
          <span className="fs-7 fw-bold"></span>
          <span className="fs-6 fw-bold">{openingRequest.project_name}</span>
        </div>
        <div
          title="Limpar Alarme"
          onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
          onClick={() => setShowModalClearAlarm(true)}
          >
          <i className={isHovered ? 'bi-trash3-fill' : 'bi-trash3'} style={{cursor: "pointer"}}></i>
        </div>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold">Nº CMB: </span>
        <span className="fs-6 fw-bold">{openingRequest.cmb_number}</span>
      </div>
      <div id='bluetooth' title='Número Bluetooth'>
        <span className="fs-7 fw-bold"><i className="bi-bluetooth"></i> </span>
        <span className="fs-6 fw-bold">{openingRequest.bluetooth}</span>
      </div>
      <div id='request_from_user' title={`Usuário Solicitante: ${openingRequest.user_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-person-circle"></i> </span>
        <span className="fs-6 fw-bold">{openingRequest.user_name}</span>
      </div>
      <div id='date_alarm' title={`Data e Hora da Solicitação de Abertura`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-alarm"></i> </span>
        <span className="fs-6 fw-bold">{moment(openingRequest.created_at).format('DD/MM/YY HH:mm:ss')}</span>
      </div>

      <div className="border-bottom border-dark border-opacity-50"></div>

      <div id="leds" className="d-flex justify-content-between gap-3 me-1" style={{ marginTop: '1px'}}>
        <div>
          <button style={{ marginTop: "1px", border: 0, backgroundColor: "#01a011", paddingTop: 0, paddingBottom: 0, paddingLeft: "4px", paddingRight: "4px", borderRadius: "4px", fontSize: "11px", fontWeight: "bold", marginRight: "8px", color: "white"}}
            onClick={() => setShowModalDoorRelease(true)}>
            Aprovar
          </button>
          <button style={{ marginTop: "1px", border: 0, backgroundColor: "#dd0202", paddingTop: 0, paddingBottom: 0, paddingLeft: "4px", paddingRight: "4px", borderRadius: "4px", fontSize: "11px", fontWeight: "bold", color: "white" }}
            onClick={() => setShowModalDoorDenied(true)}>
            Negar
          </button>
        </div>
        <div className="animation-blink" style={{height: "17px"}}><LedYellow /></div>
      </div>

      <AlertModal
        id='alert-1'
        show={showModalDoorRelease}
        title='Solicitação de Abertura - Aprovação'
        key={'showModalDoorRelease'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => handleOpenDoorRelease(openingRequest.measuring_box_id, openingRequest.cmb_number)}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalDoorRelease(false)}}
        isLoading={isLoading}
        >
        {`Confirma o envio da aprovação de abertura da caixa CMB nº ${openingRequest.cmb_number}?`}
      </AlertModal>

      <AlertModal
        id='alert-2'
        show={showModalDoorDenied}
        title='Solicitação de Abertura - Negação'
        key={'showModalDoorDenied'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => handleOpenDoorDenied(openingRequest.measuring_box_id, openingRequest.cmb_number)}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalDoorDenied(false)}}
        isLoading={isLoading}
        >
        {`Confirma o envio da negação de abertura da caixa CMB nº ${openingRequest.cmb_number}?`}
      </AlertModal>

      <AlertModal
        id='alert-show-modal-clear-alarm'
        show={showModalClearAlarm}
        title='Limpar Alarme'
        key={'alertShowModalClearAlarm'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => handleClearAlarm(openingRequest)}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalClearAlarm(false)}}
        isLoading={isLoading}
        >
        {`Confirma a limpeza do alarme da caixa CMB nº ${openingRequest.cmb_number}?`}
      </AlertModal>

    </div>
  );
}

export {MeasuringBoxOpeningRequests}