/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div
        className='d-flex flex-lg-row-fluid h-lg-100 h-lg-100 w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-1 justify-content-center'
        style={{
          height: '400px',
          backgroundImage: `url(${toAbsoluteUrl('/media/misc/login_bg.webp')})`,
        }}
      >
        <div className='d-flex flex-column justify-content-top align-items-center mt-20 col-8 col-sm-5 col-md-5 col-lg-6'>
          <h1 className='d-flex text-white fs-2qx fw-bolder text-center'>
            <p className='px-10'>Soluções em Sistemas de Energia Elétrica</p>
          </h1>
        </div>
      </div>

      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-2'
        style={{backgroundColor: '#eeeeee'}}
      >
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <Link to='/' className='d-flex flex-column flex-center'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-cmb-v3.png')}
              className='h-lg-150px mb-sm-0 mb-lg-6'
            />
          </Link>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
        </div>

        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold fs-base'>
            <a href='/privacy_policy_cmb_v1.html' className='px-5' target='_blank'>
              Termos de uso
            </a>

            <a href='#' className='px-5 text-primary' target='_blank'>
              Planos
            </a>

            <a href='#' className='px-5 text-blue' target='_blank'>
              Contato
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
