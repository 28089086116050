import { FC, useEffect, useState } from "react"
import { SensorInfo } from "./SensorInfo"
import { MeasuringBoxesWarningsQueryResponse, SensorTypes, MeasuringBoxesWarnings, TypeSensor, TypeResponseSuccess } from "../core/_models"
import clsx from "clsx"
import "../sensors.css"
import { MeasuringBoxOpeningRequests } from "./MeasuringBoxOpeningRequests"
import { useQuery } from "react-query"
import moment from "moment"
import {
  getAlarmOpenDoorFilter,
  getAlarmBoltFilter,
  getAlarmLockDoorFilter,
  getAlarmCutCableFilter,
  getAlarmEnergyFilter,
  getAlarmMaximumTimeOpenDoorFilter,
  getAlarmSoundFilter,
  getAlarmVibrationFilter,
  getAlarmViolationFilter,
  getOpeningRequestsFilter,
  clearAllAlarmRequestOpenDoor,
  clearAllAlarmDoor,
  clearAllAlarmBolt,
  clearAllAlarmDoorLock,
  clearAllAlarmVibration,
  clearAllAlarmEnergy,
  clearAllAlarmViolation,
  clearAllAlarmSound,
  clearAllAlarmMaxTimeOpenDoor,
  clearAllAlarmCableCut,
} from "../core/_requests"
import { useAuth } from "../../../modules/auth"
import { KTSVG, useDebounce } from "../../../../_metronic/helpers"
import { MeasuringBoxDefault } from "./MeasuringBoxDefault"
import { AlertModal } from "../../../components/AlertModal"
import { toast } from "react-toastify"

const Sensors: FC = () => {
  const {currentUser} = useAuth()
  const [selectedSensor, setSelectedSensor] = useState<TypeSensor>(SensorTypes.request_opening_of_safe)
  const [isHovered, setIsHovered] = useState(false)
  const [logBoxes, setLogBoxes] = useState<Array<MeasuringBoxesWarnings>>([])
  const [logBoxesFiltered, setLogBoxesFiltered] = useState<Array<MeasuringBoxesWarnings>>([])
  const [showModalClearAllAlarms, setShowModalClearAllAlarms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const secondsToRefresh = 60 * 1
  const [refetchingSeconds, setRefetchingSeconds] = useState(secondsToRefresh)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const timer = async () => {
    setTimeout(async () => {
      if (refetchingSeconds === 0) {
        await refetchOpeningRequests()
        await refetchAlarmOpenDoorFilter()
        await refetchAlarmBoltFilter()
        await refetchAlarmLockDoorFilter()
        await refetchAlarmMaximumTimeOpenDoorFilter()
        await refetchAlarmViolationFilter()
        await refetchAlarmVibrationFilter()
        await refetchAlarmSoundFilter()
        await refetchAlarmEnergyFilter()
        await refetchAlarmCutCableFilter()
        setRefetchingSeconds(secondsToRefresh)
        return
      }
      setRefetchingSeconds(refetchingSeconds - 1)
    }, 1000)
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLogBoxesFiltered(
        logBoxes.filter((logBoxes) => {
          return logBoxes.cmb_number?.toString() === debouncedSearchTerm
        })
      )
    } else {
      setLogBoxesFiltered(logBoxes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, logBoxes])

  const {
    isFetching: isFetchingOpeningRequests,
    refetch: refetchOpeningRequests,
    data: openingRequests,
  } = useQuery(
    'OPENING_REQUESTS_QUERY',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getOpeningRequestsFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const openingRequests = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.request_opening_of_safe.id) {
        setLogBoxes(openingRequests)
      }

      return openingRequests
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmOpenDoorFilter,
    refetch: refetchAlarmOpenDoorFilter,
    data: alarmOpenDoorFilter,
  } = useQuery(
    'ALARM-OPEN-DOOR-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmOpenDoorFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmOpenDoorFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.door.id) {
        setLogBoxes(alarmOpenDoorFilter)
      }

      return alarmOpenDoorFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmBoltFilter,
    refetch: refetchAlarmBoltFilter,
    data: alarmBoltFilter,
  } = useQuery(
    'ALARM-BOLT-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmBoltFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmBoltFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.bolt.id) {
        setLogBoxes(alarmBoltFilter)
      }

      return alarmBoltFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmLockDoorFilter,
    refetch: refetchAlarmLockDoorFilter,
    data: alarmLockDoorFilter,
  } = useQuery(
    'ALARM-LOCK-DOOR-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmLockDoorFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmLockDoorFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.door_lock.id) {
        setLogBoxes(alarmLockDoorFilter)
      }

      return alarmLockDoorFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmMaximumTimeOpenDoorFilter,
    refetch: refetchAlarmMaximumTimeOpenDoorFilter,
    data: alarmMaximumTimeOpenDoorFilter,
  } = useQuery(
    'ALARM-MAXIMUM-TIME-OPEN-DOOR-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmMaximumTimeOpenDoorFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmMaximumTimeOpenDoorFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.maximum_time_for_open_door.id) {
        setLogBoxes(alarmMaximumTimeOpenDoorFilter)
      }

      return alarmMaximumTimeOpenDoorFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmViolationFilter,
    refetch: refetchAlarmViolationFilter,
    data: alarmViolationFilter,
  } = useQuery(
    'ALARM-VIOLATION-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmViolationFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmViolationFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.violation.id) {
        setLogBoxes(alarmViolationFilter)
      }

      return alarmViolationFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmVibrationFilter,
    refetch: refetchAlarmVibrationFilter,
    data: alarmVibrationFilter,
  } = useQuery(
    'ALARM-VIBRATION-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmVibrationFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmVibrationFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.vibration.id) {
        setLogBoxes(alarmVibrationFilter)
      }

      return alarmVibrationFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmSoundFilter,
    refetch: refetchAlarmSoundFilter,
    data: alarmSoundFilter,
  } = useQuery(
    'ALARM-SOUND-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmSoundFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmSoundFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.sound.id) {
        setLogBoxes(alarmSoundFilter)
      }

      return alarmSoundFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmEnergyFilter,
    refetch: refetchAlarmEnergyFilter,
    data: alarmEnergyFilter,
  } = useQuery(
    'ALARM-ENERGY-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmEnergyFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmEnergyFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.energy.id) {
        setLogBoxes(alarmEnergyFilter)
      }

      return alarmEnergyFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmCutCableFilter,
    refetch: refetchAlarmCutCableFilter,
    data: alarmCutCableFilter,
  } = useQuery(
    'ALARM-CUT-CABLE-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmCutCableFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 5000,
        }
      )
      const alarmCutCableFilter = response?.data as MeasuringBoxesWarnings[]
      if (selectedSensor?.id === SensorTypes.cable_cut_alarm.id) {
        setLogBoxes(alarmCutCableFilter)
      }

      return alarmCutCableFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    timer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchingSeconds])

  async function handleClearAllAlarms() {
    const companyId = (currentUser?.last_company_id) ? +  currentUser.last_company_id : 0;
    setIsLoading(true)
    let res: TypeResponseSuccess | undefined
    try {
      switch (selectedSensor?.id) {
        case SensorTypes.request_opening_of_safe.id:
          res = await clearAllAlarmRequestOpenDoor(companyId)
          if (res?.success) refetchOpeningRequests()
          break;
        case SensorTypes.door.id:
          res = await clearAllAlarmDoor(companyId)
          if (res?.success) refetchAlarmOpenDoorFilter()
          break;
        case SensorTypes.bolt.id:
          res = await clearAllAlarmBolt(companyId)
          if (res?.success) refetchAlarmBoltFilter()
          break;
        case SensorTypes.door_lock.id:
          res = await clearAllAlarmDoorLock(companyId)
          if (res?.success) refetchAlarmLockDoorFilter()
          break;
        case SensorTypes.vibration.id:
          res = await clearAllAlarmVibration(companyId)
          if (res?.success) refetchAlarmVibrationFilter()
          break;
        case SensorTypes.energy.id:
          res = await clearAllAlarmEnergy(companyId)
          if (res?.success) refetchAlarmEnergyFilter()
          break;
        case SensorTypes.violation.id:
          res = await clearAllAlarmViolation(companyId)
          if (res?.success) refetchAlarmViolationFilter()
          break;
        case SensorTypes.sound.id:
          res = await clearAllAlarmSound(companyId)
          if (res?.success) refetchAlarmSoundFilter()
          break;
        case SensorTypes.maximum_time_for_open_door.id:
          res = await clearAllAlarmMaxTimeOpenDoor(companyId)
          if (res?.success) refetchAlarmMaximumTimeOpenDoorFilter()
          break;
        case SensorTypes.cable_cut_alarm.id:
          res = await clearAllAlarmCableCut(companyId)
          if (res?.success) refetchAlarmCutCableFilter()
          break;
        default:
          break;
      }
    } catch (error: any) {
      toast.error(`Ocorreu um erro ao tentar limpar todos os alarmes: ${error?.message}`)
    } finally {
      if (res?.success) {
        toast.success('Todos os Alarmes foram limpos com sucesso!')
      } else {
        toast.error('Ocorreu um erro ao tentar limpar todos os alarmes.')
      }
      setIsLoading(false)
      setShowModalClearAllAlarms(false)
    }
  }

  return (
    <>
    <div id="sensors_page" className="d-flex flex-row">

      <div
        id="side_menu"
        className="d-flex flex-column custom-height overflow-scroll w-300px bg-secondary pt-3 pb-3 ps-3 pe-3 rounded"
        >

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.request_opening_of_safe.id})}
          onClick={() => {
            setLogBoxes(openingRequests || [])
            setSelectedSensor(SensorTypes.request_opening_of_safe)
          }}>
          <SensorInfo sensor={SensorTypes.request_opening_of_safe} amount={openingRequests?.length || 0} isFetching={isFetchingOpeningRequests} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door.id})}
          onClick={() => {
            setLogBoxes(alarmOpenDoorFilter || [])
            setSelectedSensor(SensorTypes.door)
          }}>
          <SensorInfo sensor={SensorTypes.door} amount={alarmOpenDoorFilter?.length || 0} isFetching={isFetchingAlarmOpenDoorFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.bolt.id})}
          onClick={() => {
            setLogBoxes(alarmBoltFilter || [])
            setSelectedSensor(SensorTypes.bolt)
          }}>
          <SensorInfo sensor={SensorTypes.bolt} amount={alarmBoltFilter?.length || 0} isFetching={isFetchingAlarmBoltFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door_lock.id})}
          onClick={() => {
            setLogBoxes(alarmLockDoorFilter || [])
            setSelectedSensor(SensorTypes.door_lock)
          }}>
          <SensorInfo sensor={SensorTypes.door_lock} amount={alarmLockDoorFilter?.length || 0} isFetching={isFetchingAlarmLockDoorFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.vibration.id})}
          onClick={() => {
            setLogBoxes(alarmVibrationFilter || [])
            setSelectedSensor(SensorTypes.vibration)
          }}>
          <SensorInfo sensor={SensorTypes.vibration} amount={alarmVibrationFilter?.length || 0} isFetching={isFetchingAlarmVibrationFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.energy.id})}
          onClick={() => {
            setLogBoxes(alarmEnergyFilter || [])
            setSelectedSensor(SensorTypes.energy)
          }
        }>
          <SensorInfo sensor={SensorTypes.energy} amount={alarmEnergyFilter?.length || 0} isFetching={isFetchingAlarmEnergyFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.violation.id})}
          onClick={() => {
            setLogBoxes(alarmViolationFilter || [])
            setSelectedSensor(SensorTypes.violation)
          }}>
          <SensorInfo sensor={SensorTypes.violation} amount={alarmViolationFilter?.length || 0} isFetching={isFetchingAlarmViolationFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.sound.id})}
          onClick={() => {
            setLogBoxes(alarmSoundFilter || [])
            setSelectedSensor(SensorTypes.sound)
          }}>
          <SensorInfo sensor={SensorTypes.sound} amount={alarmSoundFilter?.length || 0} isFetching={isFetchingAlarmSoundFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.maximum_time_for_open_door.id})}
          onClick={() => {
            setLogBoxes(alarmMaximumTimeOpenDoorFilter || [])
            setSelectedSensor(SensorTypes.maximum_time_for_open_door)
          }}>
          <SensorInfo sensor={SensorTypes.maximum_time_for_open_door} amount={alarmMaximumTimeOpenDoorFilter?.length || 0} isFetching={isFetchingAlarmMaximumTimeOpenDoorFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.cable_cut_alarm.id})}
          onClick={() => {
            setLogBoxes(alarmCutCableFilter || [])
            setSelectedSensor(SensorTypes.cable_cut_alarm)
          }}>
          <SensorInfo sensor={SensorTypes.cable_cut_alarm} amount={alarmCutCableFilter?.length || 0} isFetching={isFetchingAlarmCutCableFilter} />
        </button>
      </div>

      <div id="boxes_area" className="d-flex flex-column w-100 custom-height">
        <div id="header" className="d-flex flex-row ms-1 mb-2 bg-secondary rounded ps-3 pe-3">
          <div className="d-flex flex-column justify-content-center col-sm-8 fs-4 fw-bold">
            <div>
              <i className={selectedSensor?.icon}> </i>{selectedSensor?.description}
            </div>
            <div>
              {!selectedSensor
              ? <><i className="bi-arrow-bar-left"></i> Selecione um tipo sensor da lista para visualizar as caixas</>
              : logBoxesFiltered.length > 1
              ? `${logBoxesFiltered.length} caixas sendo visualizadas`
              : `${logBoxesFiltered.length} caixa sendo visualizada`
              }
            </div>
          </div>
          <div className="col-sm-3 pt-2 pb-2">
            <div className="d-flex">
              <div>
                <input
                  className="form-control"
                  placeholder='Pesquisar Nº CMB'
                  type='text'
                  name='search_measuring_box'
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </div>
              <div>
                <button
                  className="border-0 ms-10 p-0 m-0"
                  style={{ backgroundColor: "transparent" }}
                  title="Limpar Todos os Alarmes"
                  onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
                  onClick={() => setShowModalClearAllAlarms(true)}
                  disabled={!logBoxes.length}
                  >
                  <i className={(isHovered && logBoxes.length) ? 'bi-trash3-fill fs-2qx' : 'bi-trash3 fs-2qx'}
                    style={{cursor: logBoxes.length ? "pointer" : "default", margin: 0, padding: 0, color: logBoxes.length ? "#ff3007" : "#AAAAAA"}}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-1 d-flex align-items-center justify-content-center gap-1">
            {!isFetchingOpeningRequests
            ? <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <KTSVG path='/media/icons/duotune/general/timer.svg' className='svg-icon-size-(12px) '/>
                </div>
                <div className="fs-3" style={{ fontFamily: "Courier Prime", marginTop: "6px" }}>
                  {`${moment(new Date('2024-01-01 01:00:00')).subtract(-refetchingSeconds, 'seconds').format('mm:ss')}`}
                </div>
              </div>
            : <div className="fs-2">
                <i className="bi-hourglass-split"></i>
              </div>
            }
          </div>
        </div>

        <div id="boxes" className="h-100 bg-secondary rounded ms-1 overflow-scroll">
          <div className="d-flex flex-wrap p-2">
            {selectedSensor && logBoxesFiltered.map((logBox) => {
              return (
                <div className="me-2 mb-2" key={logBox.id}>
                  {selectedSensor.id === SensorTypes.request_opening_of_safe.id
                  ? <MeasuringBoxOpeningRequests openingRequest={logBox} refetch={refetchOpeningRequests}/>
                  : <MeasuringBoxDefault sensorTypeId={selectedSensor.id} logBox={logBox} refetch={
                    selectedSensor.id === SensorTypes.door.id
                    ? refetchAlarmOpenDoorFilter
                    : selectedSensor.id === SensorTypes.bolt.id
                    ? refetchAlarmBoltFilter
                    : selectedSensor.id === SensorTypes.door_lock.id
                    ? refetchAlarmLockDoorFilter
                    : selectedSensor.id === SensorTypes.maximum_time_for_open_door.id
                    ? refetchAlarmMaximumTimeOpenDoorFilter
                    : selectedSensor.id === SensorTypes.violation.id
                    ? refetchAlarmViolationFilter
                    : selectedSensor.id === SensorTypes.vibration.id
                    ? refetchAlarmVibrationFilter
                    : selectedSensor.id === SensorTypes.sound.id
                    ? refetchAlarmSoundFilter
                    : selectedSensor.id === SensorTypes.energy.id
                    ? refetchAlarmEnergyFilter
                    : selectedSensor.id === SensorTypes.cable_cut_alarm.id
                    ? refetchAlarmCutCableFilter
                    : () => []
                  }/>}
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </div>

    <AlertModal
      id='alert-show-modal-clear-all-alarms'
      show={showModalClearAllAlarms}
      title='Limpar Todos os Alarmes'
      key={'alert-show-modal-clear-all-alarms'}
      buttonConfirm={{title: 'Confirmar', fnCallback: handleClearAllAlarms}}
      buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalClearAllAlarms(false)}}
      isLoading={isLoading}
      >
      {`Confirma a limpeza de todos os alarmes?`}
    </AlertModal>

    </>
  )
}

export {Sensors}