import { FC } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { getGoogleMapsApiKey } from './core/_requests'
import { GoogleMaps } from './GoogleMaps'

const MapPage: FC = () => {

  const {data: googleMapsApiKey, isFetching: isFetchingGoogleMapsApiKey} = useQuery(
    `GOOGLE_MAPS_API_KEY`,
    async () => {
      const res = await getGoogleMapsApiKey()
      return res.google_maps_api_key
    },
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.error(err)
        toast.error(`Erro ao carregar a chave de API do Google Maps: ${err.message}`)
      },
    }
  )

  return (
    <>
      {isFetchingGoogleMapsApiKey
        ? 'Aguarde...'
        : googleMapsApiKey && <GoogleMaps googleMapsApiKey={googleMapsApiKey} />
      }
    </>
  )

}

export {MapPage}