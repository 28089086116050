import { ID, Response } from "../../../../_metronic/helpers";

export type TypeSensor = {
  id: number;
  icon: string;
  description: string;
}

export type TypeSensors = {
  door: TypeSensor;
  bolt: TypeSensor;
  door_lock: TypeSensor;
  vibration: TypeSensor;
  energy: TypeSensor;
  violation: TypeSensor;
  sound: TypeSensor;
  maximum_time_for_open_door: TypeSensor;
  request_opening_of_safe: TypeSensor;
  cable_cut_alarm: TypeSensor;
}

export const SensorTypes: TypeSensors = {
  request_opening_of_safe: {id: 9, icon: 'bi-unlock', description: 'Solicitação de abertura'},
  door: {id: 1, icon: 'bi-door-closed', description: 'Portas'},
  bolt: {id: 2, icon: 'bi-file-lock2', description: 'Ferrolhos'},
  door_lock: {id: 3, icon: 'bi-key', description: 'Trava dos ferrolhos'},
  vibration: {id: 4, icon: 'bi-phone-vibrate', description: 'Vibração'},
  energy: {id: 5, icon: 'bi-plug', description: 'Energia'},
  violation: {id: 6, icon: 'bi-box-arrow-right', description: 'Violação'},
  sound: {id: 7, icon: 'bi-volume-up', description: 'Som'},
  maximum_time_for_open_door: {id: 8, icon: 'bi-door-open', description: 'Tempo máx. abertura de porta'},
  cable_cut_alarm: {id: 11, icon: 'bi-scissors', description: 'Alarme de corte de cabo'},
}

export type MeasuringBoxesWarnings = {
  id: ID,
  description: string,
  measuring_box_id: ID,
  address: string,
  bluetooth: number,
  cmb_number: ID,
  default_password: string,
  company_name: string,
  project_id: ID,
  project_name: string,
  user_id: ID,
  user_name: string,
  created_at: Date,
  type_id: number,
  enabled: boolean,
}

export type MeasuringBoxesWarningsQueryResponse = Response<Array<MeasuringBoxesWarnings>>

export type TypeResponseSuccess = {
  success: boolean
}

export type PortType = {
  name: string,
  description: string,
}

export type BoltType = {
  name: string,
  description: string,
}

export type DoorLockType = {
  name: string,
  description: string,
}

export const Ports: {[id: number]: PortType} = {
  12: {name: "port1", description: "Porta 1"},
  13: {name: "port2", description: "Porta 2"},
  14: {name: "port3", description: "Porta 3"},
  15: {name: "port4", description: "Porta 4"},
}

export const Bolts: {[key: string]: BoltType} = {
  16: {name: "bolt1", description: "Ferrolho 1"},
  17: {name: "bolt2", description: "Ferrolho 2"},
}

export const DoorLocks: {[key: string]: DoorLockType} = {
  18: {name: "doorLock1", description: "Trava Ferrolho 1"},
  19: {name: "doorLock2", description: "Trava Ferrolho 2"},
}
